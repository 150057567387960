<template>
    <div class="analysisReply">
        <div class="analysisReply-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>分析答辩</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>分析答辩</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>分析答辩</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-table :data="tableData" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" label="名称" align="center"></el-table-column>
            <el-table-column prop="file_name" label="答案" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <a v-if="scope.row.file_path" class="look-detail" :href="scope.row.file_path">下载</a>
                    <a v-else class="look-detail disabled">下载</a>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                trainId: Number(this.$route.query.xlId) || null,
                studentId: Number(this.$route.query.id) || null,
                competitionId: Number(this.$route.query.cId) || null,
                tableData: [],
                role:null,
            }
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        mounted() {
            this.getLyInfo()
        },
        methods: {
            getLyInfo() {
                let params = {}
                if (this.trainId) {
                    params.ts_id = this.trainId
                }
                if (this.studentId) {
                    params.user_id = this.studentId
                }
                if (this.competitionId) {
                    params.competition_id = this.competitionId
                }
                this.$http.axiosGetBy(this.$api.decorate_studentLyTrainAnswer, params, (res) => {
                    if (res.code === 200) {
                        let tmp = {}
                        if (res.data.length === 0) {
                            tmp = {
                                name: '分析答辩',
                                file_name: '暂无数据',
                                file_path: '',
                            }
                        } else {
                            tmp = {
                                name: '分析答辩',
                                id: res.data[0].id,
                                file_name: res.data[0].file_name,
                                file_path: res.data[0].file_path,
                            }
                        }
                        this.tableData.push(tmp)
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .look-detail {
        color: #0200D8;
        font-weight: 500;
        &:hover {
            color: #5864e4;
        }
        &.disabled {
            cursor: not-allowed;
            color: #aaa;
        }
    }
    /deep/.el-dialog__header{
        background: #0200D8;
        span{
            color: #FEFEFE;
            font-weight:400;
            font-size: 16px;
            /*line-height: 30px;*/
        }
    }
    .look-detail + .look-detail {
        margin-left: 10px;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .analysisReply{
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
    .el-breadcrumb {
        line-height: 40px;
    }
</style>