<template>
    <div class="paperDetail-content">
        <div class="questionType-item" v-for="(headlineItem, headlineKey, headlineIndex) in paperDetail"
             :key="`bottom_data_${headlineIndex}`" :id="`questionType_${headlineKey}`">
            <div class="questionType-title" v-if="headlineItem.list.length > 0">
                <span class="title-index">{{headlineIndex + 1 | formatChinese}}、</span>
                <span class="title">{{headlineKey | formatHeadLineType}}</span>
                <span class="subtitle">(共{{headlineItem.total || paperDetail[headlineKey].total || paperDetail[headlineKey].total_num }}题)</span>
            </div>
            <div class="question-item" v-for="(questionItem, questionIndex) in headlineItem.list"
                 :key="`bottom_data_${questionIndex}`">
                <div class="question-title">
                    <span>{{questionIndex + 1}}.</span>
                    <span style="font-weight: 500">【{{headlineKey | formatHeadLineType}}】</span>
                    <span class="title" v-html="questionItem.title"></span>
                    <!--                    <span style="margin-left: 10px;color: #666">({{questionItem.score}}分)</span>-->
                </div>
                <div class="answer-box">
                    <!--      单选或者判断      -->
                    <template>
                        <el-radio-group
                                v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'"
                                v-model="questionItem.stu_answer[0]" class="question-answer">
                            <el-radio :disabled="true" :label="answerIndex" class="answer-item"
                                      v-for="(answerItem, answerIndex) in questionItem.options">
                                <span>{{answerIndex + 1 | formatNumber}}、</span>{{answerItem}}
                            </el-radio>
                        </el-radio-group>
                        <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : 'question-analysis question-analysis-error'"
                             v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'"
                             style="justify-content: space-between">
                            <div class="question-analysis-left">
                                <span>正确答案：<strong
                                        class="strong-correct">{{questionItem.answer[0] + 1 | formatNumber}}</strong></span>
                            </div>
                            <div class="answer-judge answer-judge-correct"
                                 v-if="questionItem.is_true === 1">回答正确
                            </div>
                            <div class="answer-judge answer-judge-error" v-else>回答错误</div>
                        </div>
                    </template>
                    <!--     多选       -->
                    <template>
                        <el-checkbox-group :disabled="true" v-if="headlineKey === 'multiple_data'"
                                           v-model="questionItem.stu_answer" class="question-answer">
                            <el-checkbox :label="answerIndex" class="answer-item"
                                         v-for="(answerItem, answerIndex) in questionItem.options">
                                {{answerIndex + 1 | formatNumber}}、{{answerItem}}
                            </el-checkbox>
                        </el-checkbox-group>
                        <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : 'question-analysis question-analysis-error'"
                             v-if="headlineKey === 'multiple_data'"
                             style="justify-content: space-between">
                            <div class="question-analysis-left">
                                                <span>正确答案：<strong class="strong-correct"
                                                                   v-for="(correctItem,correctIndex) in questionItem.answer">{{correctItem + 1 | formatNumber}}</strong></span>
                            </div>
                            <div class="answer-judge answer-judge-correct"
                                 v-if="questionItem.is_true === 1">回答正确
                            </div>
                            <div class="answer-judge answer-judge-error" v-else>回答错误</div>
                        </div>
                    </template>
                    <!--      填空      -->
                    <div class="question-answer"
                         style="display: flex;margin-top: 20px; flex-direction:row;align-items: start "
                         v-if="headlineKey === 'blank_data'">
                        <div class="answer-item" style="margin-top: 0;">学生答案：</div>
                        <div class="answer-content">
                            <template v-if="questionItem.stu_answer.length > 0">
                                <div class="answer-item answer-after"
                                     style="margin-top: 0;padding: 0;line-height: 19px"
                                     v-for="(answerItem, answerIndex) in questionItem.stu_answer">
                                    {{answerIndex+1}}.
                                    <strong v-if="questionItem.is_true == 1"
                                                              :style="questionItem.answer[answerIndex] === questionItem.stu_answer[answerIndex] ? 'color:#1AB163;font-weight:400':'font-weight:400'">{{answerItem}}</strong>
                                    <strong v-if="questionItem.is_true == 0"
                                            :style="questionItem.answer.includes(answerItem) ? 'color:#1AB163;font-weight:400':'font-weight:400'">{{answerItem}}</strong>
                                </div>
                            </template>
                            <template v-else><span class="null-answer">未答题</span></template>
                        </div>
                    </div>
                    <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : 'question-analysis question-analysis-error'"
                         v-if="headlineKey === 'blank_data'" style="justify-content: space-between">
                        <div class="question-analysis-left">
                                            <span>正确答案：<strong class="strong-correct strong-after"
                                                               style="color: #3BB400"
                                                               v-for="(rightAnswerItem, rightAnswerIndex) in questionItem.answer">{{rightAnswerIndex+1}}.{{rightAnswerItem}}</strong></span>
                        </div>
                        <div class="answer-judge answer-judge-correct"
                             v-if="questionItem.is_true === 1 ">回答正确
                        </div>
                        <div class="answer-judge answer-judge-error" v-else>回答错误</div>
                    </div>
                    <!--      问答      -->
                    <div class="question-answer"
                         style="display: flex;margin-top: 20px; flex-direction:row;align-items: start "
                         v-if="headlineKey === 'answer_data'">
                        <div class="answer-item" style="margin-top: 0;">学生答案：</div>
                        <div class="answer-content">
                            <template v-if="questionItem.stu_answer.length > 0">
                                <div class="answer-item answer-after"
                                     style="margin-top: 0;padding: 0;line-height: 19px">
                                    {{questionItem.stu_answer[0]}}
                                </div>
                            </template>
                            <template v-else>
                                <span class="null-answer">未答题</span>
                            </template>
                        </div>
                    </div>
                    <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : questionItem.is_true === 0 ?'question-analysis question-analysis-error':'question-analysis question-not-check'"
                         v-if="headlineKey === 'answer_data'" style="justify-content: space-between">
                        <div class="question-analysis-left">
                            <span>正确答案：<strong class="strong-correct strong-after" style="color: #333">{{questionItem.answer[0]}}</strong></span>
                        </div>
                        <div class="isAnalysis">
                            <span @click="questionItem.isAnalysisShow = !questionItem.isAnalysisShow">{{questionItem.isAnalysisShow? '收起解析' : '查看解析'  }}</span>
                        </div>
                        <div class="answer-judge answer-judge-correct"
                             v-if="questionItem.is_true === 1 ">回答正确
                        </div>
                        <div class="answer-judge answer-judge-error" v-if="questionItem.is_true === 0 ">回答错误</div>
                        <div class="answer-judge answer-not-check" v-if="questionItem.is_true === 2 ">未批改</div>
                    </div>
                </div>
                <div class="isAnalysis-content" v-show="questionItem.isAnalysisShow">
                    <span class="title">题目解析：</span>
                    <span class="analysis-span" v-html="questionItem.analysis"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StudentAnswerDetail",
        props: ['paperDetail'],
        data() {
            return {}
        },
        filters: {
            // 题型
            formatHeadLineType(type) {
                const types = {
                    'single_data': '单选题',
                    'multiple_data': '多选题',
                    'judge_data': '判断题',
                    'blank_data': '填空题',
                    'answer_data': '问答题',
                }
                return types[type]
            },
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
            // 汉字一二三四五六
            formatChinese(nums) {
                const numbers = {
                    '1': '一',
                    '2': '二',
                    '3': '三',
                    '4': '四',
                    '5': '五',
                    '6': '六',
                    '7': '七',
                    '8': '八',
                }
                return numbers[nums]
            },
        },
    }
</script>

<style scoped lang="scss">
    .null-answer {
        color: #7D7D7D;
    }

    .paperDetail-content {
        padding: 16px 0;

        .questionType-item {
            margin-top: 20px;

            .questionType-title {
                display: flex;
                align-items: center;
                margin-left: 30px;

                .title-index {

                }

                .title {
                    font-size: 16px;
                    color: #343441;
                    font-weight: 400;
                }

                .subtitle {
                    font-size: 14px;
                    color: #343441;
                    margin-left: 10px;
                }
            }

            .question-item {
                font-size: 14px;
                color: #343441;

                .question-title {
                    margin-top: 20px;
                    background: #F2F3FA;
                    line-height: 50px;
                    padding: 0px 40px;

                    ::v-deep p {
                        display: inline;
                    }
                }

                .answer-box {
                    .question-answer {
                        padding: 0 40px;
                        display: flex;
                        flex-direction: column;

                        .answer-item {
                            margin-top: 20px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333;

                            &.el-radio {
                                white-space: normal;
                            }

                            ::v-deep .el-radio__input {
                                white-space: normal;

                                &.is-checked {
                                    & + .el-radio__label {
                                        color: #1122D8;
                                    }

                                    .el-radio__inner {
                                        border-color: #1122D8;
                                        background: #fff;

                                        &::after {
                                            background-color: #1122D8;
                                        }
                                    }
                                }

                                .el-radio__inner {
                                    &:hover {
                                        border-color: #1122D8;
                                    }
                                }
                            }

                            &.el-checkbox {
                                white-space: normal;
                            }

                            ::v-deep .el-checkbox__input {
                                white-space: normal;

                                &.is-checked {
                                    & + .el-checkbox__label {
                                        color: #1122D8;
                                    }

                                    .el-checkbox__inner {
                                        background-color: #1122D8;
                                        border-color: #1122D8;
                                    }
                                }

                                &.is-indeterminate {
                                    .el-checkbox__inner {
                                        background-color: #1122D8;
                                        border-color: #1122D8;
                                    }
                                }

                                .el-checkbox__inner {
                                    &:hover {
                                        border-color: #1122D8;
                                    }
                                }
                            }
                        }

                        .answer-content {
                            display: flex;
                            flex-wrap: wrap;

                            .answer-item {
                                margin-left: 30px;

                                &:first-of-type {
                                    margin-left: 0;
                                }
                            }

                            //.answer-after{
                            //  &:after{
                            //    content: '、';
                            //  }
                            //}
                            //.answer-after:last-child{
                            //  &:after{
                            //    content: '';
                            //  }
                            //}
                        }
                    }

                    .question-analysis-error {
                        background: #FCF1ED;
                    }

                    .question-analysis-correct {
                        background: #F3FFF8;
                    }

                    .question-not-check {
                        background: #F2F3FA;
                    }

                    .question-analysis {
                        position: relative;
                        margin: 50px 30px 0;
                        padding: 13px 54px;
                        display: flex;
                        flex-wrap: wrap;

                        span {
                            margin-right: 60px;
                            line-height: 1;
                            font-size: 14px;
                            font-weight: 400;

                            .strong-error {
                                color: #FF0000;
                            }

                            .strong-correct {
                                color: #3BB400;
                            }

                            .strong-after {
                                &:after {
                                    content: '、';
                                }

                                &:last-child {
                                    &:after {
                                        content: '';
                                    }
                                }
                            }

                            strong {
                                font-weight: 400;
                            }
                        }

                        .answer-judge {
                            position: absolute;
                            left: 40px;
                            top: -30px;
                            line-height: 1;
                            padding: 8px 14px;
                            border-radius: 4px 4px 0px 0px;
                        }

                        .answer-judge-error {
                            background: #FCF1ED;
                            color: #FF0000;
                        }

                        .answer-judge-correct {
                            background: #F3FFF8;
                            color: #3BB400;
                        }

                        .answer-not-check {
                            background: #F2F3FA;
                        }

                        .isAnalysis {
                            display: flex;
                            align-items: center;

                            span {
                                cursor: pointer;
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .isAnalysis-content {
        padding: 18px 54px;
        border: 1px solid #EEEEEE;
        font-size: 14px;
        margin: 0 30px;

        .title {
            color: #FF0000;
        }

        .analysis-span {
            ::v-deep p {
                display: inline;
                font-size: 14px;
                color: #333;
            }
        }
    }
</style>